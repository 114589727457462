import React from "react";

export default function Banner(){
    return(
        <div style={{paddingTop: '70px'}}>
             <div className="Logistics-banner-bg">
                <div className="container">
                    <h1 className="banner-sub-pages-head">Logistics & Storage</h1>
                </div>
             </div>
        </div>
    )
}