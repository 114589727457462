import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from "../../components/Euipment/Banner";
import CenterSection from "../../components/Euipment/CenterSection";
import ThreeTierSection from "../../components/Euipment/ThreeTierSection";

export default function Equipment() {
  return (
    <>
      <HelmetProvider>
      <Helmet>
        <title>LIGHT SPEED SOLAR | Equipment & Supply</title>
        <meta
          name="description"
          content="We’re truly a “one stop shop” for all solar related logistical needs, regardless of scale or complexity..."
        />
      </Helmet>
      </HelmetProvider>
      <Banner />
      {/* <CenterSection /> */}
      <ThreeTierSection />
    </>
  );
}
