import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BannerSection from '../../components/home/Banner';
import EquipmentSection from '../../components/home/Equipment';
import LogisticsSection from '../../components/home/Logistics';
import FinancingSection from '../../components/home/Financing';
import AboutSection from '../../components/home/About';
import ContactSection from '../../components/home/Contact';

export default function Home(){
    return(
        <div>
          <HelmetProvider>
      <Helmet>
        <title>LIGHT SPEED SOLAR | The Best in Solar</title>
        <meta name="description" content="Our carefully curated relationships with the world’s most reputable tier-1 brands, OEMs and carriers..." />
      </Helmet>
      </HelmetProvider>
        <BannerSection />
        <EquipmentSection />
        <LogisticsSection />
        {/* <FinancingSection /> */}
        <AboutSection />
        <ContactSection />
      </div>
    );
}