import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from "../../components/About/Banner";
import TextSection from "../../components/About/TextSection";
import ThreeTierSection from "../../components/About/ThreeTierSection";

export default function About() {
  return (
    <>
       <HelmetProvider>
      <Helmet>
        <title>LIGHT SPEED SOLAR | Our Story</title>
        <meta
          name="description"
          content="Lightspeed Solar is dedicated to furthering the expansion of Solar Power..."
        />
      </Helmet>
      </HelmetProvider>
      <Banner />
      <TextSection />
      <ThreeTierSection />
    </>
  );
}
