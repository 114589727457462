import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../../components/Contact/Banner';
import ContactSection from '../../components/home/Contact';

export default function Contact(){
    return(
        <>
         <HelmetProvider>
           <Helmet>
        <title>LIGHT SPEED SOLAR | Contact</title>
        <meta
          name="description"
          content="Our contact process will be start once the user fills the form in contact form..."
        />
      </Helmet>
      </HelmetProvider>
           <Banner />
           <ContactSection />
        </>
    )
}