// Logistics.jsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../../components/Logistics/Banner';
import TwoTierSection from '../../components/Logistics/TwoTierSection';

export default function Logistics() {
  return (
    <>
      <HelmetProvider>
      <Helmet>
        <title>LIGHT SPEED SOLAR | Logistics & Storage</title>
        <meta name="description" content="We’re truly a “one stop shop” for all solar related logistical needs, regardless of scale or complexity..." />
      </Helmet>
      </HelmetProvider>
      <Banner />
      <TwoTierSection />
    </>
  );
}
