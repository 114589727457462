import React from "react";
import Logistics from "../../assets/images/logistics-card.svg";
import Storage from "../../assets/images/storage-card.svg";

export default function TwoTierSection() {
  return (
    <>
      <div className="container hide-overflow">
        <div className="pad-tp-bt-100">
          {/* <h2 className="blue-sub-head center-text" data-aos='fade-right'>Logistics & Storage</h2> */}
          <div className="two-tier-section">
            <div className="two-tier-card2">
              <h2 className="black-sub-head">Logistics</h2>
              <p className="sub-text">
                We’re truly a “one stop shop” for all solar related logistical
                needs, regardless of scale or complexity.
              </p>
              <p className="sub-text">
                Our focus on and loyalty to a select few of the nations foremost
                3PLs ensures we receive the most aggressive rates, have
                dedicated carriers that consistently deliver in a timely manner
                and properly handle all equipment throughout transit.
              </p>
              <div className="pad-tp-30">
                <a href="/contact" className="banner-btn">
                  <div className="slide-layer"></div>
                  Let's chat
                </a>
              </div>
            </div>
            <div className="two-tier-card1">
              <img src={Logistics} alt="image" width="100%" className="mobile-hide" />
            </div>
          </div>
          <div className="two-tier-section pad-tp-50 logistics-align">
          <div className="two-tier-card1">
              <img src={Storage} alt="image" width="100%" data-aos='fade-right' className="mobile-hide" />
            </div>
            <div className="two-tier-card2">
              <h2 className="black-sub-head" data-aos='fade-left'>Storage</h2>
              <p className="sub-text" data-aos='fade-left'>
                We offer nationwide storage, at industry low rates, for as
                little or long as our clients require in secure climate
                controlled warehouses.
              </p>
              <ul>
                <li>
                  <p className="sub-text" data-aos='fade-left'>
                    100+ storage facilities, nationwide
                  </p>
                </li>
                <li>
                  <p className="sub-text" data-aos='fade-left'>
                    Short or long term storage optional
                  </p>
                </li>
                <li>
                  <p className="sub-text" data-aos='fade-left'>
                    Job-site delivery & fulfillment capabilitie
                  </p>
                </li>
                <li>
                  <p className="sub-text" data-aos='fade-left'>Drayage and Transloading services</p>
                </li>
              </ul>
              <div className="pad-tp-30" data-aos='fade-left'>
                <a href="/contact" className="banner-btn">
                  <div className="slide-layer"></div>
                  Let's chat
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
