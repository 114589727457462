import React from "react";

export default function Footer(){
    return(
        <>
          <footer className="footer-bg">
             <div className="container">
                <div className="pad-tb-bt-30">
                    <p>Copyright 2024. Lightspeed Solar, LLC. All Rights Reserved.</p>
                </div>
             </div>
          </footer>
           
        </>
    )
}