import React, { useRef, FormEvent, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

export default function ContactSection() {
  const form = useRef<HTMLFormElement>(null);
  const [success, setSuccess] = useState(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string>("");
  const [companyError, setCompanyError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_z8p9pfs",
          "template_jcwv404",
          form.current,
          "R6jMMOa-AFoKGGYJD"
        )
        .then(
          (result: any) => {
            console.log(result.text);
            setSuccess(true);
          },
          (error: any) => {
            console.log(error.text);
          }
        );
    }
  };

  if (success === true) {
    console.log("Timeout function is executed");
    setTimeout(() => {
      setSuccess(false);
      setInterestedInFinancing(null);
    }, 5000);
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = e.target.value;
    const nameRegex = /^[a-zA-Z\s]*$/; // Regex pattern to allow only alphabets and spaces
    if (!nameRegex.test(nameValue)) {
      setNameError("Name should contain only alphabets and spaces.");
    } else {
      setNameError("");
    }
  };
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const companyValue = e.target.value;
    // You can adjust the regex pattern based on your requirements for company names
    const companyRegex = /^[a-zA-Z\s&.,'-]*$/; // Example: Allows alphabets, spaces, &, ., , ', and -
    if (!companyRegex.test(companyValue)) {
      setCompanyError("Invalid characters in company name.");
    } else {
      setCompanyError("");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format check
    if (!emailRegex.test(emailValue)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = e.target.value;
    const phoneRegex = /^\d{10}$/; // Pattern for 10-digit phone number
    if (!phoneRegex.test(phoneValue)) {
      setPhoneError("Phone number should be 10 digits.");
    } else {
      setPhoneError("");
    }
  };
  const [interestedInFinancing, setInterestedInFinancing] = useState<
    boolean | null
  >(null);

  // Extract the 'financing' query parameter from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const financingParam = urlParams.get("financing");

    if (financingParam === "true") {
      setInterestedInFinancing(true);
    } else if (financingParam === "false") {
      setInterestedInFinancing(false);
    } else {
      setInterestedInFinancing(null); // No query parameter, leave as user choice
    }
  }, []);

  useEffect(() => {
    // Check overall form validity whenever any field's error changes
    setFormValid(!(nameError || companyError || emailError || phoneError));
  }, [nameError, companyError, emailError, phoneError]);

  return (
    <div id="contact">
      <div className="pad-tp-bt-100">
        <div className="container hide-overflow">
          <div className="contact-two-tier-section">
            {/* <div className="contact-card1">
              <div className="blue-bg-sub-card">
                <p data-aos="fade-right">Contact</p>
                <h2 data-aos="fade-right">Our Contact Process</h2>
              </div>
            </div> */}
            <div className="contact-card2">
              <h3 className="blue-sub-head center-text">
                <span className="black-clr">Get in</span> Touch
              </h3>
              {success === true ? (
                <div>
                  <p className="success-message">
                    Thank you, we’ll be in touch shortly
                  </p>
                </div>
              ) : (
                <>
                  <form ref={form} onSubmit={sendEmail} className="form-align">
                    <div className="field-rows-align">
                      <div className="contact-fields-align">
                        <input
                          type="text"
                          placeholder="Name"
                          name="from_name"
                          className="fields-align"
                          onChange={handleNameChange}
                          required
                        />
                        {nameError && (
                          <p className="error-message">{nameError}</p>
                        )}
                      </div>
                      <div className="contact-fields-align">
                        <input
                          type="text"
                          placeholder="Company Name"
                          name="company_name"
                          className="fields-align"
                          onChange={handleCompanyChange}
                          required
                        />
                        {companyError && (
                          <p className="error-message">{companyError}</p>
                        )}
                      </div>
                    </div>
                    <div className="field-rows-align">
                      <div className="contact-fields-align">
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          className="fields-align"
                          onChange={handleEmailChange}
                          required
                        />
                        {emailError && (
                          <p className="error-message">{emailError}</p>
                        )}
                      </div>
                      <div className="contact-fields-align">
                        <input
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          className="fields-align"
                          onChange={handlePhoneChange}
                          required
                        />
                        {phoneError && (
                          <p className="error-message">{phoneError}</p>
                        )}
                      </div>
                    </div>
                    {/* Radio buttons for Interested in financing */}
                    <div className="field-radio-align">
                      Interested in financing
                      <input
                        type="radio"
                        name="financing"
                        value="yes"
                        style={{ marginLeft: "10px" }}
                        checked={interestedInFinancing === true} // Set checked value based on state
                        onChange={() => setInterestedInFinancing(true)} // Update state on change
                      />{" "}
                      Yes
                      <input
                        type="radio"
                        name="financing"
                        value="no"
                        style={{ marginLeft: "10px" }}
                        checked={interestedInFinancing === false} // Set checked value based on state
                        onChange={() => setInterestedInFinancing(false)} // Update state on change
                      />{" "}
                      No
                    </div>

                    <textarea
                      name="message"
                      placeholder="Tell us about your needs..."
                      className="multi-line-field-align"
                      required
                    />

                    <button
                      type="submit"
                      className="contact-btn"
                      disabled={!formValid}
                    >
                      <div className="slide-layer1"></div>
                      Send
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
