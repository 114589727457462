import React from "react";

export default function Banner(){
    return(
        <>
         <div style={{paddingTop: '70px'}}>
             <div className="finance-banner-bg">
                <div className="container hide-overflow">
                    <h1 className="banner-sub-pages-head">Financing, on your terms.</h1>
                    <ul>
                <li>
                  <p className="finance-sub-text" data-aos='fade-left'>Industry low rates.</p>
                </li>
                <li>
                  <p className="finance-sub-text" data-aos='fade-left'>Net-30 to Net-90 terms.</p>
                </li>
                <li>
                  <p className="finance-sub-text" data-aos='fade-left'>Approval in under a week.</p>
                </li>
                <li>
                  <p className="finance-sub-text" data-aos='fade-left'>
                    Credit lines from $100,000 to $10,000,000+ optional.
                  </p>
                </li>
              </ul>
              <div className="apply-btn-align">
                <a href="/contact?financing=true" className="finance-button">
                  <div className="slide-layer1"></div>
                  Apply Now
                </a>
              </div>
                </div>
             </div>
          </div>
        </>
    );
}