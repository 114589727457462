import React from "react";
import BannerImg from "../../assets/images/test-banners.png";

export default function Banner() {
  return (
    <>
      <div style={{ paddingTop: "70px" }}>
        <div className="equip-banner-bg">
          <div className="container hide-overflow">
            <h1 className="banner-sub-pages-head">Equipment Supply</h1>
          </div>
        </div>
        <div className="container pad-tp-50">
          <p className="sub-text mar-bt-0">
            Our strategic partnerships with a few of the world’s most bankable
            Tier-1 manufacturers allows us to deliver significant value through
            custom tailored supply solutions that suit the unique needs of our
            clientele, at industry low rates.
          </p>
        </div>
      </div>
    </>
  );
}
