// Navbar.js
import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/solar-logo-test.png";

export default function Navbar() {
  const [menu, setMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(window.location.pathname);

  const handleOpenToggleMenu = () => {
    setMenu(true);
  };
  const handleCloseToggleMenu = () => {
    setMenu(false);
  }
  const handleLinkClick = (link:any) => {
    setActiveLink(link);
  };

  return (
    <header className="nav-bg">
      <div className="container">
        <div className="Navbar-align">
          <div>
            <a href="/">
              <img src={Logo} alt="logo" className="Navbar-logo" />
            </a>
          </div>
          <ul className="Navbar-buttons-align web-menu">
            <li>
              <a href="/equipment"
              className={activeLink === "/equipment" ? "active" : ""}
              onClick={() => handleLinkClick("/equipment")}
              >Equipment Supply</a>
            </li>
            <li>
              <a href="/logistics&storage"
               className={activeLink === "/logistics&storage" ? "active" : ""}
               onClick={() => handleLinkClick("/logistics&storage")}
              >Logistics & Storage</a>
            </li>
            <li>
              <a href="/financing"
               className={activeLink === "/financing" ? "active" : ""}
               onClick={() => handleLinkClick("/financing")}
              >Financing</a>
            </li>
            <li>
              <a href="/about"
               className={activeLink === "/about" ? "active" : ""}
               onClick={() => handleLinkClick("/about")}
              >Our Story</a>
            </li>
            <li>
              <button className="black-link">
                <a href="/contact">
                  <div className="slide-layer"></div>
                  Contact
                </a>
              </button>
            </li>
          </ul>
          <button className="Navbar-toggle" onClick={handleOpenToggleMenu}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          {menu === true ? (
            <div className="mobile-menu">
               <button className="Navbar-close-btn" onClick={handleCloseToggleMenu}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            <div className="mobile-menu-align">
             
            <ul className="Navbar-buttons-align">
              <li>
                <a href="/equipment">Equipment Supply</a>
              </li>
              <li>
                <a href="/logistics&storage">Logistics & Storage</a>
              </li>
              <li>
                <a href="/financing">Financing</a>
              </li>
              <li>
                <a href="/about">Our Story</a>
              </li>
              <li>
                <button className="black-link">
                  <a href="/contact">
                    <div className="slide-layer"></div>
                    Contact
                  </a>
                </button>
              </li>
            </ul>
            </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </header>
  );
}
