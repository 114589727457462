import React from "react";

export default function TextSection() {
  return (
    <>
      <div className="container hide-overflow">
        <div className="pad-tp-50">
          <div className="about-two-tier-sec">
            {/* <div className="equ-two-tier-card">
              <div className="blue-bg-sub-card">
                <p data-aos='fade-right'>About</p>
                <h2 data-aos='fade-right'>Welcome to Our LightSpeedSolar Company</h2>
              </div>
            </div> */}
            <div>
            {/* <h2 className="black-sub-head" data-aos='fade-left'>About</h2> */}
            <p className="sub-text">
            Lightspeed Solar is dedicated to furthering the expansion of Solar
            Power in the United States.
          </p>
          <p className="sub-text">
            Our hyper focus on all aspects of solar equipment distribution
            combined with our streamlined operations, deep rooted connections
            with select manufacturers and overall industry expertise facilitate
            our ability to add significant value to each our clients.
          </p>
          <p className="sub-text" data-aos='fade-left'>
            To date, we’ve supported the distribution of over 1.4 GW of various
            solar equipment nationwide.
          </p>
          <p className="sub-text" data-aos='fade-left'>
            The needs of our clients, which include residential and commercial
            installers, EPCs and utility scale developers is the driving force
            behind every decision we make.
          </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
