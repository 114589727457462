import React from "react";
import Logistics from "../../assets/images/Logistics&Storage-card.png";

export default function LogisticsSection() {
  return (
    <div id="logistics" className="cement-bg">
      {/* <div className="container">
        <div className='pad-tp-bt-140'>
          <h2 className="blue-sub-head center-text" data-aos='fade-right'>Logistics & Storage</h2>
          <div className="two-tier-section pad-tp-50">
            <div className="two-tier-card2">
              <h2 className="black-sub-head">Logistics</h2>
              <p className="sub-text">
                We’re truly a “one stop shop” for all solar related logistical
                needs, regardless of scale or complexity.
              </p>
              <p className="sub-text">
                Our focus on and loyalty to a select few of the nations foremost
                3PLs ensures we receive the most aggressive rates, have
                dedicated carriers that consistently deliver in a timely manner
                and properly handle all equipment throughout transit.
              </p>
              <h2 className="black-sub-head">Storage</h2>
              <p className="sub-text">
              We offer nationwide storage, at industry low rates, for as little or long as our clients require in secure climate controlled warehouses.
              </p>                                               
              <ul>
                <li>
                  <p className="sub-text">100+ storage facilities, nationwide</p>
                </li>
                <li>
                  <p className="sub-text">Short or long term storage optional</p>
                </li>
                <li>
                  <p className="sub-text">Job-site delivery & fulfillment capabilitie</p>
                </li>
                <li>
                  <p className="sub-text">Drayage and Transloading services</p>
                </li>
              </ul>
              <div className="pad-tp-30">
                <a href="#contact" className='banner-btn'>
                  <div className="slide-layer"></div>
                  Let's chat</a>
              </div>
            </div>
            <div className="two-tier-card1">
              <img src={Logistics} alt="image" width="100%" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="hide-overflow">
        <div className="home-logistics-banner">
          <div className="banner-align">
            {/* <img src={Logo} alt="logo" height='25px' /> */}
            <h1 className="banner-head" data-aos='fade-right'>Logistics & Storage</h1>
            <p className="banner-text" data-aos='fade-right'>
              We’re truly a “one stop shop” for all solar related logistical
              needs, regardless of scale or complexity.
            </p>
            <div className="banner-btn-align" data-aos='fade-right'>
              <a href="/logistics&storage" className="banner-btn">
                <div className="slide-layer"></div>
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
