import React from 'react';
import CardImage from '../../assets/images/Equipment-card.jpg';
import Installer from '../../assets/images/Installer.svg';
import NewCommunity from '../../assets/images/New-Community.svg';
import Community from '../../assets/images/Community.svg';
import Developer from '../../assets/images/Developer.svg';

export default function EquipmentSection(){
    return(
    <div id="equipment">
        <div className='container hide-overflow'>
            <div className='pad-tp-bt-140'>
                <div className='two-tier-section'>
                    {/* <div className='two-tier-card1'>
                        <img src={CardImage} alt='image' style={{width: '100%'}} data-aos='fade-right' />
                    </div> */}
                    <div>
                        <h2 className='blue-sub-head center-text' data-aos='fade-left'>Equipment Supply</h2>
                        <p className='sub-text center-text' data-aos='fade-left'>Our strategic partnerships with a few of the world’s most bankable Tier-1 manufacturers allows us to deliver significant value through custom tailored supply solutions that suit the unique needs of our clientele, at industry low rates.</p>
                    </div>
                </div>
                <div>
                  <p className='mini-center-text' data-aos='fade-up'>We proudly serve all sectors of the solar industry, nationwide.</p>
                </div>
                <div className='three-tier-section'>
                   <div className='three-tier-card'>
                      <img src={Community} alt='icon' data-aos='fade-up'/>
                      <h3 data-aos='fade-up'>Residential & Commercial Installers</h3>
                   </div>
                   <div className='three-tier-card'>
                      <img src={NewCommunity} alt='icon' data-aos='fade-up' />
                      <h3 data-aos='fade-up'>DG & Community Solar EPCs</h3>
                   </div>
                   <div className='three-tier-card'>
                      <img src={Developer} alt='icon' data-aos='fade-up'/>
                      <h3 data-aos='fade-up'>Utility Scale Developers</h3>
                   </div>
                </div>
                <div className='pad-tp-30 center-text'> 
                <a href="/contact" className='banner-btn'>
                    <span className='slide-layer'></span>
                    Let's chat</a>
                </div>
            </div>
        </div>
    </div>
    );
}