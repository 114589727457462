import React from "react";
import BannerImage from "../../assets/images/Home-about-banner.png";
import Manufacturing from "../../assets/images/aboutus-card.png";
import Forklift from "../../assets/images/Forklift-card.png";
import Semitruck from "../../assets/images/semitruck-card.png";
import Warehouse from "../../assets/images/Warehouse-card.png";

export default function AboutSection() {
  return (
    <div id="about">
      {/* <div className="cement-bg">
        <div className="pad-tp-bt-140">
          <div className="container">
            <div className="two-tier-section">
              <div className="two-tier-card1">
                <img src={Manufacturing} alt="image" width="100%" height="548px" />
              </div>
              <div className="two-tier-card2">
                <h2 className="blue-sub-head">About</h2>
                <p className="sub-text">
                  Lightspeed Solar is dedicated to furthering the expansion of
                  Solar Power in the United States.
                </p>
                <p className="sub-text">
                  Our hyper focus on all aspects of solar equipment distribution
                  combined with our streamlined operations, deep rooted
                  connections with select manufacturers and overall industry
                  expertise facilitate our ability to add significant value to
                  each our clients.
                </p>
                <p className="sub-text">
                  To date, we’ve supported the distribution of over 1.4 GW of
                  various solar equipment nationwide.
                </p>
                <p className="sub-text">
                  The needs of our clients, which include residential and
                  commercial installers, EPCs and utility scale developers is
                  the driving force behind every decision we make.
                </p>
              </div>
            </div>
          </div>
          <h2 className="blue-sub-head center-text pad-tp-50">What We do</h2>
            <div className="about-three-tier-section">
              <div className="about-three-tier-card">
                <img src={Forklift} alt="forklift" width="100%" height="300px" />
                <div className="about-three-tier-card-text-align">
                  <h3>Equipment Supply</h3>
                  <p className="sub-text">Industry low pricing on the worlds most bankable tier-1 modules and other equipment.</p>
                  <div className="about-btn-align">
                    <a href="#">
                      <div className="slide-layer1"></div>
                      Explore more</a>
                  </div>
                </div>
              </div>
              <div className="about-three-tier-card">
                <img src={Semitruck} alt="semitruck" width="100%" height="300px" />
                <div className="about-three-tier-card-text-align">
                <h3>Logistics</h3>
                  <p className="sub-text">Fast, secure and on-time delivery to anywhere in the U.S.</p>
                  <div className="about-btn-align">
                    <a href="#">
                    <div className="slide-layer1"></div>
                      Explore more</a> 
                  </div>
                </div>
              </div>
              <div className="about-three-tier-card">
                <img src={Warehouse} alt="warehouse" width="100%" height="300px" />
                <div className="about-three-tier-card-text-align">
                <h3>Storage</h3>
                  <p className="sub-text">100+ climate controlled warehouses nationwide available, as needed.</p>
                  <div className="about-btn-align">
                    <a href="#">
                      <div className="slide-layer1"></div>
                      Explore more</a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div> */}
      <div className="hide-overflow home-about-align">
        <div className="home-about-banner">
          <div className="banner-align">
            {/* <img src={Logo} alt="logo" height='25px' /> */}
            <h1 className="banner-head" data-aos='fade-left'>Our Story</h1>
            <p className="banner-text" data-aos='fade-left'>
              Lightspeed Solar is dedicated to furthering the expansion of Solar
              Power in the United States.
            </p>
            <div className="banner-btn-align" data-aos='fade-left'>
              <a href="/about" className="banner-btn">
                <div className="slide-layer"></div>
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
