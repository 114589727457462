import React from "react";
import BannerImage from "../../assets/images/solar-test.png";

export default function BannerSection() {
  return (
    <div style={{ paddingTop: "70px" }}>
      <div className="home-banner-bg">
        <div className="banner-align">
          <h1 className="banner-head">The Best in Solar</h1>
          <p className="banner-text">
            Our carefully curated relationships with the world’s most reputable
            tier-1 brands, OEMs and carriers combined with our lean corporate
            structure fuel our ability to add unprecedented value to our
            clientele.
          </p>
          <div className="banner-btn-align">
            <a href="/about" className="banner-btn">
              <div className="slide-layer"></div>
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
