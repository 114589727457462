import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from '../../components/Financing/Banner';
import FinacningSection from '../../components/Financing/FinancingSection';

export default function Financing(){
    return(
        <>
          <HelmetProvider>
        <Helmet>
        <title>LIGHT SPEED SOLAR | Finanicing, on your terms</title>
        <meta name="description" content="Industry low rates Net-30 to Net-90 terms..." />
      </Helmet>
      </HelmetProvider>
           <Banner />
           {/* <FinacningSection /> */}
        </>
    )
}