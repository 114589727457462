import React from "react";
import Installer from '../../assets/images/Installer.svg';
import NewCommunity from '../../assets/images/New-Community.svg';
import Community from '../../assets/images/Community.svg';
import Developer from '../../assets/images/Developer.svg';

export default function ThreeTierSection(){
    return(
        <>
           <div className="container hide-overflow">
            <div className="pad-tp-bt-100">
                  <p className='equ-mini-center-text'>We proudly serve all sectors of the solar industry, nationwide.</p>
                
                <div className='three-tier-section'>
                   <div className='three-tier-card'>
                      <img src={Community} alt='icon'/>
                      <h3 data-aos='fade-up'>Residential & Commercial Installers</h3>
                      {/* <p className='sub-text' data-aos='fade-up'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                   </div>
                   <div className='three-tier-card'>
                      <img src={NewCommunity} alt='icon'/>
                      <h3 data-aos='fade-up'>DG & Community Solar EPCs</h3>
                      {/* <p className='sub-text' data-aos='fade-up'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                   </div>
                   <div className='three-tier-card'>
                      <img src={Developer} alt='icon'/>
                      <h3 data-aos='fade-up'>Utility Scale Developers</h3>
                      {/* <p className='sub-text' data-aos='fade-up'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                   </div>
                </div>
                <div className='pad-tp-30 center-text'> 
                <a href="/contact" className='banner-btn'>
                    <div className='slide-layer'></div>
                    Let's chat</a>
                </div>
                </div>
            </div>
        </>
    )
}