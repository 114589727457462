import React, { useEffect } from 'react';
import { Router, Route, Routes, BrowserRouter } from "react-router-dom";
// Layout components
import ScrollToTop from 'react-scroll-to-top';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
// Components
import Home from './pages/home';
import Equipment from './pages/equipment';
import Logistics from './pages/logistics';
import Financing from './pages/financing';
import About from './pages/about';
import Contact from './pages/contact';
// Styles
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(()=>{
    Aos.init();
  }, [])
  return (
    <div className="App">
       <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
       <Route path='/' element={<Home />} />
       <Route path='/equipment' element= {<Equipment />} />
       <Route path='/logistics&storage' element={<Logistics />} />
       <Route path='/financing' element={<Financing />} />
       <Route path='/about' element={<About />} />
       <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
