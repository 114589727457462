import React from "react";
import Forklift from "../../assets/images/Forklift-card.png";
import Semitruck from "../../assets/images/semitruck-card.png";
import Warehouse from "../../assets/images/Warehouse-card.png";

export default function ThreeTierSection() {
  return (
    <>
      <div className="pad-tp-bt-100 hide-overflow">
        <h2 className="blue-sub-head center-text" data-aos="fade-up">
          What We do
        </h2>
        <div className="about-three-tier-section pad-tp-30">
          <div className="about-three-tier-card">
            <img
              src={Forklift}
              alt="forklift"
              width="100%"
              data-aos="fade-up"
            />
            <div className="about-three-tier-card-text-align">
              <h3 data-aos="fade-up">Equipment Supply</h3>
              <p className="sub-text" data-aos="fade-up">
                Industry low pricing on the worlds most bankable tier-1 modules
                and other equipment.
              </p>
              <div className="about-btn-align" data-aos="fade-up">
                <a href="/equipment">
                  <div className="slide-layer1"></div>
                  Explore more
                </a>
              </div>
            </div>
          </div>
          <div className="about-three-tier-card">
            <img
              src={Semitruck}
              alt="semitruck"
              width="100%"
              data-aos="fade-up"
            />
            <div className="about-three-tier-card-text-align">
              <h3 data-aos="fade-up">Logistics</h3>
              <p className="sub-text" data-aos="fade-up">
                Fast, Secure, and On-time delivery nationwide.
              </p>
              <div className="about-btn-align" data-aos="fade-up">
                <a href="/logistics&storage">
                  <div className="slide-layer1"></div>
                  Explore more
                </a>
              </div>
            </div>
          </div>
          <div className="about-three-tier-card">
            <img
              src={Warehouse}
              alt="warehouse"
              width="100%"
              data-aos="fade-up"
            />
            <div className="about-three-tier-card-text-align">
              <h3 data-aos="fade-up">Storage</h3>
              <p className="sub-text" data-aos="fade-up">
              100+ climate-controlled warehouses available as needed.
              </p>
              <div className="about-btn-align" data-aos="fade-up">
                <a href="/logistics&storage">
                  <div className="slide-layer1"></div>
                  Explore more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
